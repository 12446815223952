import React from 'react';
import { useDispatch } from 'react-redux';
import convertBitToBytes from '../../../../../../../utils/convertBitToBytes.utils';
import TorrentService from '../../../../../Torrent.service';
import './ResultCard.css';
import TorrentSearchService from '../../Results.service';
export default function ResultLine({result}) {
    const dispatch = useDispatch();
    
    const addTorrent = () => {
        TorrentService.addFromSearch(dispatch, result);
    };

    return <div className='ResultCard'>
    <a className='ResultCard-name' href={result.comment || result.link} target="_blank" rel="noopener noreferrer">{result.name}</a>
    <div className='ResultCard-item'><span className='ResultCard-label'>Taille:</span><span className='ResultCard-value'>{convertBitToBytes(result.size)}</span></div>
    <div className='ResultCard-item'><span className='ResultCard-label'>Seeders:</span><span className='ResultCard-value'>{result.seeders}</span></div>
    <div className='ResultCard-item'><span className='ResultCard-label'>Leechers:</span><span className='ResultCard-value'>{result.leechers}</span></div>
    <div className='ResultCard-item'><span className='ResultCard-label'>Tracker:</span><span className='ResultCard-value'>{result.trackerName}</span></div>
    <button onClick={() => !result.status && addTorrent()} className={`ResultCard-add ResultCard-item ${TorrentSearchService.getAddColor(result)} block`}>{TorrentSearchService.getAddText(result)}</button>
</div>
}