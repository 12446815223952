import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Card from '../../../../Components/Card/Card';
import Dropdown from '../../../../Components/Dropdown/Dropdown';
import Progress from '../../../../Components/Progress/Progress';
import TorrentService from '../../Torrent.service';
import Results from './Results/Results';
import './Search.css';

function Search() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const trackers = useSelector((state) => state.torrent.trackers.data);
    const isTrackersLoading = useSelector((state) => state.torrent.trackers.loading);
    const isTrackersLoaded = useSelector((state) => state.torrent.trackers.loaded);
    const searcher = useSelector((state) => state.torrent.searcher);
    const [selectedTrackers, setSelectedTrackers] = useState([]);
    const [query, setQuery] = useState(searchParams.get('query'));
    const [isSearching, setIsSearching] = useState(false);
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        if(!isTrackersLoaded && !isTrackersLoading) {
            TorrentService.getTrackers(dispatch);
        }
    }, []);

    const getTrackersId = () => trackers.map((tracker) => tracker.id);

    const selectAllTrackers = () => {
      setSelectedTrackers(getTrackersId());
    };

    const deselectAllTrackers = () => {
      setSelectedTrackers([]);
    };

    const toggleTracker = (tracker) => {
      if(selectedTrackers.includes(tracker.id)) {
          setSelectedTrackers(selectedTrackers.filter((selectedTracker) => selectedTracker !== tracker.id));
      } else {
          setSelectedTrackers([...selectedTrackers, tracker.id]);
      }
    };

    const abordSearchRequests = () => {
      requests.forEach((request) => {request.isCancelled = true;})
      requests
      .filter((request) => request.isStarted && !request.isFinished)
      .forEach((request) => {
        request.request.abord();
      });
    };

    
    const getTrackerLabel = () => {
      if(!trackers || !trackers.length) {
        return 'Chargement des trackers';
      } else if (selectedTrackers.length) {
        return `${selectedTrackers.length} trackers sélectionnés`;
      } else {
        return `Sélectionner les trackers`;
      }
    };

    const searchHandler = (ev) => {
      ev.preventDefault();
      if(isSearching) {
        abordSearchRequests();
      }

      const isNewQuery = !!query && searcher.query !== query;

      if(isNewQuery) {

        setSearchParams({query}, { replace: true });   

        setIsSearching(isNewQuery);

        setRequests(TorrentService.search(dispatch, {
          query,
          trackers: selectedTrackers.length > 0 ? selectedTrackers : getTrackersId()
        }, 5));

        if(selectedTrackers.length === 0) {
          selectAllTrackers();
        }
      }
    };

    return (
      <Card label="Rechercher">
          
        <form className="Search-form" onSubmit={searchHandler}>
          <Dropdown label={getTrackerLabel()} >
              <div className="Search-trackers">
                  <button onClick={selectAllTrackers} className="block sm warning">Tout cocher</button>
                  <button onClick={deselectAllTrackers} className="block sm warning">Tout décocher</button>
                  {trackers
                      .map((tracker) => <button key={tracker.id} className={`block sm ${selectedTrackers.includes(tracker.id) ? 'success': 'danger'}`} onClick={() => toggleTracker(tracker)}>{tracker.name}</button>)}
              </div>
          </Dropdown>
          <input type="text" placeholder='Nom du torrent (Ex: Game Of Thrones French S01 720p)' onChange={(ev) => setQuery(ev.target.value)} value={query} />
          <button type="submit">Rechercher</button>

        </form>

        {isSearching && <div className='Search-progress'>
          <Progress value={searcher.nbTrackersFinished} max={searcher.trackers.length} percent={Math.round(searcher.nbTrackersFinished / searcher.trackers.length * 100 * 100) / 100}></Progress>  
        </div>}

        {isSearching && <Results searcherResults={searcher.results}></Results>}
        
      </Card>
    );
}

export default Search;
