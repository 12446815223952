import React from 'react';
import './ResultLines.css';
import ResultLine from './ResultLine/ResultLine';
export default function ResultLines({results, sort, setSort}) {
    return <table className='ResultLines'>
    <thead>
        <tr>
            <th>Ajouter</th>
            <th className='ResultLines-name'>
                <span className={`ResultLines-sort ResultLines-sort--asc ${sort.order === 'ASC' && sort.prop === 'name' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'ASC', prop: 'name'})}>&lt;</span>
                <span>Nom</span>
                <span className={`ResultLines-sort ResultLines-sort--desc ${sort.order === 'DESC' && sort.prop === 'name' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'DESC', prop: 'name'})}>&lt;</span>
            </th>
            <th>
                <span className={`ResultLines-sort ResultLines-sort--asc ${sort.order === 'ASC' && sort.prop === 'size' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'ASC', prop: 'size'})}>&lt;</span>
                <span>Taille</span>
                <span className={`ResultLines-sort ResultLines-sort--desc ${sort.order === 'DESC' && sort.prop === 'size' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'DESC', prop: 'size'})}>&lt;</span>
            </th>
            <th>
                <span className={`ResultLines-sort ResultLines-sort--asc ${sort.order === 'ASC' && sort.prop === 'seeders' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'ASC', prop: 'seeders'})}>&lt;</span>
                <span>Seeds</span>
                <span className={`ResultLines-sort ResultLines-sort--desc ${sort.order === 'DESC' && sort.prop === 'seeders' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'DESC', prop: 'seeders'})}>&lt;</span>
            </th>
            <th>
                <span className={`ResultLines-sort ResultLines-sort--asc ${sort.order === 'ASC' && sort.prop === 'leechers' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'ASC', prop: 'leechers'})}>&lt;</span>
                <span>Leechs</span>
                <span className={`ResultLines-sort ResultLines-sort--desc ${sort.order === 'DESC' && sort.prop === 'leechers' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'DESC', prop: 'leechers'})}>&lt;</span>
            </th>
            <th className='ResultLines-tracker'>
                <span className={`ResultLines-sort ResultLines-sort--asc ${sort.order === 'ASC' && sort.prop === 'trackerName' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'ASC', prop: 'trackerName'})}>&lt;</span>
                <span>Tracker</span>
                <span className={`ResultLines-sort ResultLines-sort--desc ${sort.order === 'DESC' && sort.prop === 'trackerName' ? 'ResultLines-sort--active' : ''}`} onClick={() => setSort({order: 'DESC', prop: 'trackerName'})}>&lt;</span>
            </th>
        </tr>
    </thead>
    <tbody>
        {results
            .map((result) => <ResultLine key={result.id} result={result}></ResultLine>)}
    </tbody>
</table>
}