export default class TorrentSearchService {
    static getAddColor(result) {
        if (result.status === 'ADDED') {
            return 'success';
        } else if (result.status === 'ERROR') {
            return 'danger';
        } else if (result.seeders === 0) {
            return 'warning';
        } else {
            return 'primary'
        }
    }
    static getAddText(result) {
        if (result.status === 'ADDED') {
            return 'Ajouté';
        } else if (result.status === 'ERROR') {
            return 'Erreur';
        } else if (result.status === 'LOADING') {
            return 'Chargement';
        } else {
            return 'Ajouter'
        }
    }
}
