import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import convertBitToBytes from '../../../../../utils/convertBitToBytes.utils';
import TorrentService from '../../../Torrent.service';
import './TorrentLine.css';
export default function TorrentLine({torrent, isDeletable, isShareable, isUserVisible}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isShare, setIsShared] = useState(torrent.shared);

    useEffect(() => {
        setIsShared(torrent.shared);
    }, [torrent.shared]);

    const goToDetail = () => {
        navigate(`/torrent/${torrent._id}`);
    };

    const updateShare = (ev) => {
        ev.stopPropagation();
        TorrentService.updateShare(dispatch, torrent._id, ev.target.checked);
    };

    const remove = (ev) => {
        ev.stopPropagation();
        TorrentService.delete(dispatch, torrent._id);
    };

    return <tr className='TorrentLine' onClick={goToDetail}>
        <td className='TorrentLine-name'>{torrent.datas.name}</td>
        <td>{convertBitToBytes(torrent.datas.size)}</td>
        <td>{convertBitToBytes(torrent.datas.uploaded)}</td>
        <td>{dayjs(torrent.date).format('DD/MM/YYYY')}</td>
        <td><a href={torrent.url} onClick={(ev) => ev.stopPropagation()} target="_blank" rel="noopener noreferrer">{torrent.source}</a></td>
        {isShareable && <td><input type="checkbox" checked={isShare} onChange={updateShare} onClick={(ev) => ev.stopPropagation()}/></td>}
        {isDeletable && <td><button className='block sm danger' onClick={remove}>Supprimer</button></td>}
        {isUserVisible && <td>{torrent.user.name}</td>}
    </tr>
}