import React from 'react';
import './FileLine.css';
import EnvironementService from '../../../../services/Environment.service';
import { getToken } from '../../../../services/Token.service';
import convertBitToBytes from '../../../../utils/convertBitToBytes.utils';
import { getFileLink, getFileType } from '../../../../utils/torrentDetail.utils';
import Progress from '../../../../Components/Progress/Progress';

export default function FileLine({torrentId, path, file, goTo, addRssFeed, rss}) {
    return <tr className={`FileLine ${file.type === 'directory' ? 'directory' : 'file'}`} onClick={() => goTo(file)}>
        { file.progress >= 0 && file.progress < 1 && <td className="FileLine-progress"><Progress percent={(file.progress || 0) * 100} hidePercent={true}></Progress></td> }
        <td className='FileLine-name'>{file.name}</td>
        <td>{convertBitToBytes(file.size)}</td>
        <td>{file.type === 'directory' ? 'Dossier' : getFileType(file)}</td>
        <td className='FileLine-download'>
            <a
                className='block'
                onClick={(ev) => {ev.stopPropagation()}}
                href={EnvironementService.config.origin + getFileLink(torrentId, file.type, file.name, path, getToken())}
                target="_blank"
                rel="noopener noreferrer">
                <button className='sm block'>{file.type === 'directory' ? 'Télécharger le dossier' : 'Télécharger'}</button>
            </a>
        </td>
        {rss && rss.length > 0 && <td>
            <select className='sm block warning' defaultValue='' onClick={(ev) => {ev.stopPropagation()}} onChange={(ev) => addRssFeed(ev.target.value, file)}>
                <option value=''>Flux RSS</option>
                {rss.map((rss) => <option key={rss._id} value={rss._id}>{rss.name}</option>)}
            </select>
        </td>}
    </tr>
}